class QueryDetailModel {
  constructor(model) {
    this.model = model;
  }

  // 基本信息
  get title() {
    return `${placeholderText(this.model.title)}测评报告`;
  }

  get name() {
    return placeholderText(this.model.trueName);
  }

  get sex() {
    return placeholderText(findKey(GENDER_CODE, `${this.model.sex}`));
  }

  get age() {
    return placeholderText(this.model.age.toFixed(0));
  }

  get assessmentTime() {
    return placeholderText(this.model.assessmentTime);
  }

  get type() {
    return this.model.type;
  }

  // 评估结果
  get score() {
    return placeholderText(
      this.model.total?.toFixed(this.model.type == QUERY_TYPE.CNCQ ? 1 : 0),
      "分"
    );
  }

  get result() {
    return placeholderText(this.model.result);
  }

  // 表格
  get tableData() {
    var data, rule;
    switch (this.model.type) {
      case QUERY_TYPE.CSHQ:
        data = this.model.selfContent.map((item) => {
          return {
            title: placeholderText(item.title),
            content: placeholderText(item.content),
            score: placeholderText(item.score?.toFixed(0)),
          };
        });
        rule = [
          { prop: "title", label: "题目", width: 453 },
          { prop: "content", label: "填写内容", width: 120 },
          { prop: "score", label: "得分", width: null },
        ];
        break;
      case QUERY_TYPE.CNCQ:
        data = this.model.selfContent.map((item) => {
          return {
            title: placeholderText(item.title),
            content: placeholderText(item.content),
            score: placeholderText(item.score?.toFixed(1)),
          };
        });
        rule = [
          { prop: "title", label: "题目", width: 453 },
          { prop: "content", label: "填写内容", width: 120 },
          { prop: "score", label: "得分", width: null },
        ];
        break;
      case QUERY_TYPE.BISQ:
      case QUERY_TYPE.DIET:
      case QUERY_TYPE.PSQI:
      case QUERY_TYPE.AIS:
        data = this.model.selfContent.map((item) => {
          return {
            title: placeholderText(item.title),
            content: placeholderText(item.content),
          };
        });
        rule = [
          { prop: "title", label: "题目", width: 485 },
          { prop: "content", label: "填写内容", width: null },
        ];
        break;
      case QUERY_TYPE.STOP_Bang:
      case QUERY_TYPE.ESS:
      case QUERY_TYPE.Conners:
      case QUERY_TYPE.GAD:
      case QUERY_TYPE.PHQ:
      case QUERY_TYPE.ISI:
      case QUERY_TYPE.FSS:
        data = this.model.selfContent.map((item) => {
          return {
            title: placeholderText(item.title),
            score: placeholderText(item.score?.toFixed(0)),
          };
        });
        rule = [
          { prop: "title", label: "题目", width: 562 },
          { prop: "score", label: "得分", width: null },
        ];
        break;
      case QUERY_TYPE.EQC:
        data = this.model.selfContent.map((item) => {
          return {
            type: placeholderText(item.depth),
            title: placeholderText(item.title),
            score: placeholderText(item.score?.toFixed(0)),
          };
        });
        rule = [
          { prop: "type", label: "分类", width: 82 },
          { prop: "title", label: "题目", width: 560 },
          { prop: "score", label: "得分", width: null },
        ];
        break;
      case QUERY_TYPE.PSQ:
      case QUERY_TYPE.OSA_18:
        data = this.model.selfContent.map((item) => {
          return {
            depth: placeholderText(item.depth),
            title: placeholderText(item.title),
            score: placeholderText(item.score?.toFixed(0)),
          };
        });
        rule = [
          { prop: "depth", label: "纬度", width: 112 },
          { prop: "title", label: "题目", width: 538 },
          { prop: "score", label: "得分", width: null },
        ];
        break;
      default:
        break;
    }
    return {
      type: this.type,
      data,
      rule,
    };
  }

  // 图表
  get chartData() {
    switch (this.model.type) {
      case QUERY_TYPE.PHQ:
      case QUERY_TYPE.GAD:
      case QUERY_TYPE.Conners:
      case QUERY_TYPE.ESS:
      case QUERY_TYPE.CNCQ:
        return {
          max: 3,
          interval: 1,
          chartArray: this.model.depthValue,
        };
      case QUERY_TYPE.PSQ:
        return {
          max: 9,
          interval: 3,
          chartArray: this.model.depthValue,
        };
      case QUERY_TYPE.OSA_18:
        return {
          max: 28,
          interval: 7,
          chartArray: this.model.depthValue,
        };
      case QUERY_TYPE.EQC:
        return {
          max: 88,
          interval: 22,
          chartArray: this.model.depthValue,
        };
      case QUERY_TYPE.STOP_Bang:
        return {
          max: 1,
          interval: 1,
          chartArray: this.model.depthValue,
        };
      case QUERY_TYPE.ISI:
        return {
          max: 4,
          interval: 1,
          chartArray: this.model.depthValue,
        };
      default:
        return undefined;
    }
  }

  // 指导意见
  get suggestion() {
    return placeholderText(this.model.measures);
  }
}

export { QueryDetailModel };
